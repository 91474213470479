$color-green: #00FF41;
$header-height: 80px;

body {
  margin: 0;
  background-color: black;
  color: $color-green;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  border: 1px solid $color-green;
  display: flex;
  gap: 1rem;
  height: $header-height;
  justify-content: space-between;

  a:first-child {
    text-decoration: none;
  }

  a {
    color: $color-green;
    border-left: 1px solid $color-green;
    border-right: 1px solid $color-green;
    padding: 0 1rem;
    height: 100%;
    line-height: $header-height;
  }
}

.count-down {
  margin: 3rem;
  span {
    margin: 1rem;
  }
}

.img-wrapper {
  width: 100%;
  height: calc(70vh - #{$header-height});
  overflow: hidden;
  padding: 3rem;
  
  img {
    border: 5px solid $color-green;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.text {
  padding-left: 3rem;
  padding-right: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


small {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}